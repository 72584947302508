import React from 'react';
import {Alert, Col, FormGroup, Label, Row} from 'reactstrap';
import {AvField} from 'availity-reactstrap-validation';
import Printhead from './printhead';
import Vrate from './vrate';

const Wf1l640 = (props) => {
  return (
    <React.Fragment>
      <FormGroup className="mb-4">
        <Label className="col-form-label">Print Head Serials</Label>
        <Alert color="danger" role="alert">
          Please note: the following print head serial numbers are the
          original print heads that are installed with the installation
        </Alert>
        <table style={{width: '100%'}}>
          <tbody>
            <tr>
              <td>
                <Row className="mb-2 px-2">
                  <Label md="2"
                    className="col-form-label">PH Op
                  Serial*</Label>
                  <Col md="4">
                    <Printhead
                      example="W1A1234"
                      name="print_heads[wf1l640][1][serial]"
                      placeholder="PH Op Serial *"
                      pattern="^[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9]+$"
                    />
                    <AvField
                      name="print_heads[wf1l640][1][position]"
                      value="o"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[wf1l640][1][type]"
                      value="optimizer"
                      type="text"
                      hidden={true}
                    />
                  </Col>
                  <Label md="2" className="col-form-label">V.Rank
                  *</Label>
                  <Col md="4">
                    <Vrate name="print_heads[wf1l640][1][vrate]"/>
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row className="mb-2 px-2">
                  <Label md="2"
                    className="col-form-label">PH C
                  Serial*</Label>
                  <Col md="4">
                    <Printhead
                      example="W1A1234"
                      name="print_heads[wf1l640][0][serial]"
                      placeholder="PH C Serial *"
                      pattern="^[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9]+$"
                    />
                    <AvField
                      name="print_heads[wf1l640][0][position]"
                      value="c"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[wf1l640][0][type]"
                      value="color"
                      type="text"
                      hidden={true}
                    />
                  </Col>

                  <Label md="2" className="col-form-label">V.Rank
                  *</Label>
                  <Col md="4">
                    <Vrate name="print_heads[wf1l640][0][vrate]"/>
                  </Col>
                </Row>
              </td>
            </tr>
          </tbody>
        </table>
      </FormGroup>
    </React.Fragment>
  );
};

export default Wf1l640;
