import {
  CLAIM_FINAL_CHECK_STATUS,
  CLAIM_INTERNAL_CHECK_STATUS,
  CLAIM_STATUS,
} from '../components/Claim/constants';
import {ROLE, RoleNameMap} from '../components/Role/constants';
import {getLoggedInUser} from './fakebackend_helper';

const authUser = getLoggedInUser(); // @TODO get the user from selector
const userRole = authUser && RoleNameMap.get(authUser.user.role);

const parseClaimStatus = (claim) => {
  const message = [];
  if (claim.status === CLAIM_STATUS.OPEN) message.push('Open');
  if (claim.status === CLAIM_STATUS.IN_PROGRESS) message.push('In Progress');
  if (claim.status === CLAIM_STATUS.FINAL_CHECK &&
        claim.final_check_status === CLAIM_FINAL_CHECK_STATUS.GRANTED) message.push('Guarantee granted');
  if (claim.status === CLAIM_STATUS.FINAL_CHECK &&
      claim.final_check_status === CLAIM_FINAL_CHECK_STATUS.INTERNAL_GRANTED &&
      (userRole === ROLE.BROTHER_MANAGER ||
          userRole === ROLE.BROTHER_STAFF ||
          userRole === ROLE.BROTHER_SALES
      )) message.push('Internal granted');
  if (claim.status === CLAIM_STATUS.FINAL_CHECK &&
      claim.final_check_status === CLAIM_FINAL_CHECK_STATUS.INTERNAL_GRANTED &&
      userRole === ROLE.DEALER_ENGINEER
  ) message.push('Guarantee granted');
  if (claim.status === CLAIM_STATUS.FINAL_CHECK &&
      claim.final_check_status === CLAIM_FINAL_CHECK_STATUS.NOT_GRANTED) message.push('Guarantee not granted');
  else if ((userRole === ROLE.BROTHER_MANAGER ||
      userRole === ROLE.BROTHER_STAFF ||
      userRole === ROLE.BROTHER_SALES) &&
      claim.internal_check_status !== CLAIM_INTERNAL_CHECK_STATUS.NONE) message.push('Internal checked');
  return message.join(', ');
};

const parseReplacementNumbers = (claim) => {
  if (claim.other_part_replacements && claim.other_part_replacements.length > 0) {
    return claim.other_part_replacements.map((printPart) => printPart.number).join(', ');
  } else if (claim.print_head_replacements && claim.print_head_replacements.length > 0) {
    return claim.print_head_replacements.map((printHead) => printHead.part_code).join(', ');
  }
  return '-';
};

const parseReplacementNames = (claim) => {
  if (claim.other_part_replacements && claim.other_part_replacements.length > 0) {
    return claim.other_part_replacements.map((printPart) => printPart.name).join(', ');
  } else if (claim.print_head_replacements && claim.print_head_replacements.length > 0) {
    return claim.print_head_replacements.map((printHead) => printHead.part_name).join(', ');
  }
  return '-';
};

const parseReplacementPositions = (claim) => {
  if (claim.print_head_replacements && claim.print_head_replacements.length > 0) {
    return claim.print_head_replacements.map((printHead) => printHead.ph_position).join(', ');
  }
  return '-';
};

const parseReplacementTypes = (claim) => {
  if (claim.print_head_replacements && claim.print_head_replacements.length > 0) {
    return claim.print_head_replacements.map((printHead) => printHead.type).join(', ');
  }
  return '-';
};

export {
  parseClaimStatus,
  parseReplacementNumbers,
  parseReplacementNames,
  parseReplacementPositions,
  parseReplacementTypes,
};
