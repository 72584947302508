import {PRINTER_TYPE, PrinterTypeMap} from '../components/Printer/constants';

/**
 * @description selector to get all printers.
 * @param {*} state
 * @return {*}
 */
export const getPrintersSelector: (state: any) => Printer[] =
    (state) => ((state.Printers && state.Printers.data) ?
      state.Printers.data : []);

/**
 * Selector to get a list of printers with type GT3
 * @param {object} state Entire state.
 * @return {*}
 */
export const getGT3PrintersSelector: (state: any) => Printer[] =
  (state) => ((state.Printers && state.Printers.data) ?
    state.Printers.data : [])
      .filter((printer) => printer && (PrinterTypeMap.get(
          printer.printer_type) || PRINTER_TYPE.GT3) === PRINTER_TYPE.GT3,
      );

/**
 * Selector to get a list of printers with type GTX
 * @param {object} state Entire state.
 * @return {*}
 */
export const getGTXPrintersSelector: (state: any) => Printer[] =
  (state) => ((state.Printers && state.Printers.data) ?
    state.Printers.data : [])
      .filter((printer) => printer && (PrinterTypeMap.get(
          printer.printer_type) || PRINTER_TYPE.GTX) === PRINTER_TYPE.GTX,
      );

/**
 * Selector to get a list of printers with type GTXpro
 * @param {object} state Entire state.
 * @return {*}
 */
export const getGTXProPrintersSelector: (state: any) => Printer[] =
  (state) => ((state.Printers && state.Printers.data) ?
    state.Printers.data : [])
      .filter((printer) => printer && (PrinterTypeMap.get(
          printer.printer_type) || PRINTER_TYPE.GTXPRO) === PRINTER_TYPE.GTXPRO,
      );

/**
 * Selector to get a list of printers with type GTXpro-Bulk
 * @param {object} state Entire state.
 * @return {*}
 */
export const getGTXProBulkPrintersSelector: (state: any) => Printer[] =
  (state) => ((state.Printers && state.Printers.data) ?
    state.Printers.data : [])
      .filter((printer) => printer && (PrinterTypeMap.get(
          printer.printer_type) ||
        PRINTER_TYPE.GTXPRO_BULK) === PRINTER_TYPE.GTXPRO_BULK,
      );

/**
 * Selector to get a list of printers with type GTX600
 * @param {object} state Entire state.
 * @return {*}
 */
export const getGTX600PrintersSelector: (state: any) => Printer[] =
  (state) => ((state.Printers && state.Printers.data) ?
    state.Printers.data : [])
      .filter((printer) => printer && (PrinterTypeMap.get(
          printer.printer_type) || PRINTER_TYPE.GTX600) === PRINTER_TYPE.GTX600,
      );

/**
 * Selector to get a list of printers with type GTX600SB
 * @param {object} state Entire state.
 * @return {*}
 */
export const getGTX600sbPrintersSelector: (state: any) => Printer[] =
  (state) => ((state.Printers && state.Printers.data) ?
    state.Printers.data : [])
      .filter((printer) => printer && (PrinterTypeMap.get(
          printer.printer_type) || PRINTER_TYPE.GTX600SB) === PRINTER_TYPE.GTX600SB,
      );
/**
 * Selector to get a list of printers with type WF1-L640
 * @param {object} state Entire state.
 * @return {*}
 */
export const getWF1L640PrintersSelector: (state: any) => Printer[] =
  (state) => ((state.Printers && state.Printers.data) ?
    state.Printers.data : [])
      .filter((printer) => printer && (PrinterTypeMap.get(
          printer.printer_type) || PRINTER_TYPE.WF1_L640) === PRINTER_TYPE.WF1_L640,
      );

export const getPrinterByIdSelector = (state: any, id: number) =>
  (state.Printer && state.Printer.data && state.Printer.data.id === id) ?
    state.Printer.data : undefined;
