import React from 'react';
import {Alert, Col, FormGroup, Label, Row} from 'reactstrap';
import {AvField} from 'availity-reactstrap-validation';
import Printhead from './printhead';
import Vrate from './vrate';

const Gtx600sb = (props) => {
  return (
    <React.Fragment>
      <FormGroup className="mb-4">
        <Label className="col-form-label">Print Head Serials</Label>
        <Alert color="danger" role="alert">
            Please note: the following print head serial numbers are
            the original print heads that are installed with the installation
        </Alert>
        <table style={{width: '100%'}}>
          <tbody>
            <tr>
              <td>
                <Row className="mb-2 px-2">
                  <Label md="2"
                    className="col-form-label">PH
                                    Serial white *</Label>
                  <Col md="4">
                    <Printhead
                      example="6WA1234"
                      name="print_heads[gtx600sb][0][serial]"
                      placeholder="White PH (A)*"
                      pattern="^[0-9]{1}[A-Z]{2}[0-9]+$"/>
                    <AvField
                      name="print_heads[gtx600sb][0][position]"
                      value="A"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gtx600sb][0][type]"
                      value="white"
                      type="text"
                      hidden={true}
                    />
                  </Col>

                  <Label md="2"
                    className="col-form-label">
                      W(A) V.Rating *
                  </Label>
                  <Col md="4">
                    <Vrate name="print_heads[gtx600sb][0][vrate]"/>
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row className="mb-2 px-2">
                  <Label md="2"
                    className="col-form-label">PH
                                    Serial white *</Label>
                  <Col md="4">
                    <Printhead
                      example="6WA1234"
                      name="print_heads[gtx600sb][1][serial]"
                      placeholder="White PH (B)*"
                      pattern="^[0-9]{1}[A-Z]{2}[0-9]+$"/>
                    <AvField
                      name="print_heads[gtx600sb][1][position]"
                      value="B"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gtx600sb][1][type]"
                      value="white"
                      type="text"
                      hidden={true}
                    />
                  </Col>

                  <Label md="2"
                    className="col-form-label">
                      W(B) V.Rating *
                  </Label>
                  <Col md="4">
                    <Vrate name="print_heads[gtx600sb][1][vrate]"/>
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row className="mb-2 px-2">
                  <Label md="2"
                    className="col-form-label">
                      PH Serial colors *
                  </Label>
                  <Col md="4">
                    <Printhead
                      example="6CA1234"
                      name="print_heads[gtx600sb][2][serial]"
                      placeholder="Colour PH (A)*"
                      pattern="^[0-9]{1}[A-Z]{2}[0-9]+$"
                    />
                    <AvField
                      name="print_heads[gtx600sb][2][position]"
                      value="A"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gtx600sb][2][type]"
                      value="color"
                      type="text"
                      hidden={true}
                    />
                  </Col>

                  <Label md="2"
                    className="col-form-label">C(A) V.Rating
                                    *</Label>
                  <Col md="4">
                    <Vrate name="print_heads[gtx600sb][2][vrate]"/>
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row className="mb-2 px-2">
                  <Label md="2"
                    className="col-form-label">PH
                                    Serial colors *</Label>
                  <Col md="4">
                    <Printhead
                      example="6CA1234"
                      name="print_heads[gtx600sb][3][serial]"
                      placeholder="Colour PH (B)*"
                      pattern="^[0-9]{1}[A-Z]{2}[0-9]+$"
                    />
                    <AvField
                      name="print_heads[gtx600sb][3][position]"
                      value="B"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gtx600sb][3][type]"
                      value="color"
                      type="text"
                      hidden={true}
                    />
                  </Col>

                  <Label md="2"
                    className="col-form-label">
                      C(B) V.Rating *
                  </Label>
                  <Col md="4">
                    <Vrate name="print_heads[gtx600sb][3][vrate]"/>
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row className="mb-2 px-2">
                  <Label md="2"
                    className="col-form-label">PH
                    Serial special colors *</Label>
                  <Col md="4">
                    <Printhead
                      example="6CA1234"
                      name="print_heads[gtx600sb][4][serial]"
                      placeholder="Colour PH (SP-A)*"
                      pattern="^[0-9]{1}[A-Z]{2}[0-9]+$"
                    />
                    <AvField
                      name="print_heads[gtx600sb][4][position]"
                      value="SP-A"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gtx600sb][4][type]"
                      value="color"
                      type="text"
                      hidden={true}
                    />
                  </Col>

                  <Label md="2"
                    className="col-form-label">
                    C(SP-A) V.Rating *
                  </Label>
                  <Col md="4">
                    <Vrate name="print_heads[gtx600sb][4][vrate]"/>
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td>
                <Row className="mb-2 px-2">
                  <Label md="2"
                    className="col-form-label">PH
                                    Serial special colors *</Label>
                  <Col md="4">
                    <Printhead
                      example="6CA1234"
                      name="print_heads[gtx600sb][5][serial]"
                      placeholder="Colour PH (SP-B)*"
                      pattern="^[0-9]{1}[A-Z]{2}[0-9]+$"
                    />
                    <AvField
                      name="print_heads[gtx600sb][5][position]"
                      value="SP-B"
                      type="text"
                      hidden={true}
                    />
                    <AvField
                      name="print_heads[gtx600sb][5][type]"
                      value="color"
                      type="text"
                      hidden={true}
                    />
                  </Col>

                  <Label md="2"
                    className="col-form-label">
                    C(SP-B) V.Rating *
                  </Label>
                  <Col md="4">
                    <Vrate name="print_heads[gtx600sb][5][vrate]"/>
                  </Col>
                </Row>
              </td>
            </tr>
          </tbody>
        </table>
      </FormGroup>
    </React.Fragment>
  );
};

export default Gtx600sb;
