import React, {FC, useMemo} from 'react';
import Moment from 'react-moment';
import ClaimStatus from '../ClaimStatus';
import {MuiToolbarThemeWithHover} from '../../../helpers/themes/MuiThemes';
import MUIDataTable, {MUIDataTableOptions} from 'mui-datatables';
import {MuiThemeProvider, Typography} from '@material-ui/core';
import {LoadingIndicator} from '../../LoadingIndicator';
import {useHistory} from 'react-router-dom';
import {parseClaimStatus, parseReplacementNumbers, parseReplacementNames, parseReplacementPositions, parseReplacementTypes} from '../../../helpers/claim_helper';
// import {getLoggedInUser} from '../../../helpers/fakebackend_helper';
// import {ROLE, RoleNameMap} from '../../Role/constants';

interface ClaimListProps {
  isLoading: boolean,
  claims: Claim[]
}

export const ClaimList: FC<ClaimListProps> = (
    {
      isLoading,
      claims,
    }) => {
  const history = useHistory();

  // const authUser = getLoggedInUser();
  // const userRole = RoleNameMap.get(authUser.user.role) || ROLE.NONE;

  /* eslint-disable camelcase */
  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: {
        display: false,
      },
    },
    {
      label: '#FR',
      name: 'fault_report_no',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'SN',
      name: 'printer_serial_no',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Dealer',
      name: 'dealer_name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Customer',
      name: 'customer_name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Claim',
      name: 'date_of_visit',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (date_of_visit) =>
          <Moment format="DD-MM-YYYY">{date_of_visit}</Moment>,
      },
    },
    {
      label: 'Input',
      name: 'created_at',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (created_at) =>
          <Moment format="DD-MM-YYYY">{created_at}</Moment>,
      },
    },
    // {
    //   label: 'Location',
    //   name: 'storage_location',
    //   options: {
    //     filter: true,
    //     sort: true,
    //     display: userRole !== ROLE.DEALER_ENGINEER,
    //   },
    // },
    {
      label: 'Replacement',
      name: 'replacement_type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Position',
      name: 'ph_positions',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Type',
      name: 'types',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Number',
      name: 'replacement_numbers',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Name',
      name: 'replacement_names',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Claim Status',
      name: 'status',
      options: {
        // @TODO make this filterable and sortable
        filter: false,
        sort: false,
        customBodyRender: (claim) =>
          <ClaimStatus
            status={claim.status}
            final_check_status={claim.final_check_status}
            internal_check_status={claim.internal_check_status}
          />,
      },
    },
    {
      label: 'R-Value',
      name: 'r_value',
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
  ];

  const dataList = useMemo(
      () => ({
        columns: [...columns],
        rows: [...claims.map(
            (claim) => ({
              id: claim.id,
              fault_report_no: claim.fault_report_no,
              printer_serial_no: claim.printer_serial_no,
              dealer_name: claim.dealer ? claim.dealer.name : '',
              customer_name: claim.customer ? claim.customer.name : '',
              date_of_visit: claim.date_of_visit,
              created_at: claim.created_at,
              storage_location: claim.storage_location,
              replacement_type: // @TODO better handling
            claim.replacement_type === 2 ? 'Other part' : 'Print head',
              replacement_numbers: parseReplacementNumbers(claim),
              replacement_names: parseReplacementNames(claim),
              ph_positions: parseReplacementPositions(claim),
              types: parseReplacementTypes(claim),
              status: claim,
              r_value: claim.r_value,
              //     @TODO: delete this? */}
              //     <Link to={{
              //     pathname: "/claim/" + claim.id + "/delete",
              //     state: {claim: claim.id}
              //     }} className="text-danger">
              //      <i className="mdi mdi-close
              //      font-size-18 mr-3" id="deletetooltip"></i>
              //         <UncontrolledTooltip
              //         placement="top"
              //         target="deletetooltip"
              //         >
              //            Delete
              //         </UncontrolledTooltip>
              //     </Link>
            }),
        )],
      }), [claims, columns],
  );

  const options: MUIDataTableOptions = {
    download: true,
    downloadOptions: {
      filename: 'claims.csv',
    },
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    rowsPerPage: 50,
    rowsPerPageOptions: [],
    selectableRows: 'none',
    onRowClick: (rowData) => {
      history.push('/claim/' + rowData[0]);
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const newData = data.map((obj) => {
        const claim = obj.data[10];
        obj.data[10] = parseClaimStatus(claim);
        return obj;
      });
      return buildHead(columns) + buildBody(newData);
    },
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      currentRow.forEach((col) => {
        if (
          col?.toString()?.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
        ) {
          isFound = true;
        }
      });
      return isFound;
    },
    textLabels: {
      body: {
        noMatch: isLoading ?
          <LoadingIndicator fontSize={24}/> :
          'Sorry, no matching records found',
      },
    },
  };

  return (
    <MuiThemeProvider theme={MuiToolbarThemeWithHover}>
      <MUIDataTable
        title={!isLoading ? 'Claims' :
          <Typography variant="h6">Claims <LoadingIndicator/></Typography>}
        data={dataList.rows}
        columns={dataList.columns}
        options={options}
      />
    </MuiThemeProvider>
  );
};
/* eslint-enable camelcase */
