import React, {FC, useEffect, useState} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  getEngineer,
} from '../../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import ModalConfirmEngineer from
  '../../components/Modals/modal-confirmengineer';
import {getLoggedInUser} from '../../helpers/fakebackend_helper';
import {PrintersTable} from '../Printers/printers-table';
import {useLocation} from 'react-router-dom';
import {getEngineerByIdSelector} from '../../selectors/engineer';
import {createLoadingSelector} from '../../selectors/loading';
import {GET_ENGINEER} from '../../store/engineer/actionTypes';
import {EngineerCard} from '../../components/Engineer/EngineerCard';
import ClaimsTableOld from '../../pages/Claims/claims-table_old';

const EngineerShow: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const authUser = getLoggedInUser();

  const engineerId = location.pathname.split('/').pop() || 0;

  const engineerSelector = useSelector((state) =>
    getEngineerByIdSelector(state, +engineerId));

  const engineerLoadingSelector = useSelector(
      createLoadingSelector([GET_ENGINEER]),
  );

  const [engineerLoading, setEngineerLoading] = useState(true);
  const [engineer, setEngineer] = useState<FullEngineer>();

  useEffect(() => {
    dispatch(getEngineer(+engineerId));
  }, [dispatch]);

  useEffect(() => {
    setEngineer(engineerSelector);
  }, [engineerSelector]);

  useEffect(() => {
    setEngineerLoading(engineerLoadingSelector);
  }, [engineerLoadingSelector]);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          parent="/members"
          title="Member"
          breadcrumbItem={
            (engineer && engineer.firstname + ' ' + engineer.lastname) || ''
          }
        />
        <Row>
          <Col xl="4">
            <EngineerCard
              engineer={engineer}
              isLoading={engineerLoading}
            />

            {authUser.user && [
              'manager', 'staff',
            ].includes(authUser.user.role) &&
            engineer?.activation === '0' ?
              <Card className="bg-yellow">
                <CardBody>
                  <h4
                    className="card-title text-dark">
                      Confirm Registration<br/> for {engineer?.firstname + ' ' + engineer?.lastname}
                  </h4>
                  <p>The confirmation triggers e-mails</p>
                  <ModalConfirmEngineer engineer={engineer}/>
                </CardBody>
              </Card> : ''
            }
          </Col>

          <Col xl="8">
            <Card>
              <CardBody>
                <PrintersTable
                  printers={engineer?.printer || []}
                  isLoading={engineerLoading}
                />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                {
                  engineer && engineer.claims &&
                  <ClaimsTableOld claims={engineer.claims}/>
                }
                {/* <ClaimsTable*/}
                {/*    claims={engineer?.claims || []}*/}
                {/*    isLoading={engineerLoading}*/}
                {/* />*/}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EngineerShow;
